@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.callOutTextContainer {
  width: 100%;
  max-width: rem(700);
  margin: 0 auto;
  padding: rem(32) rem(16);
}


.callOutText {
  @include h3;
  position: relative;
  padding-left: rem(32);
  border-left: rem(2) solid $primary-cardinal-900;



  @include tablet {
    @include h2;
  }
}

