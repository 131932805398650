@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.section {
	@include contained(true);
}

.contentHeaderWrapper {
	background-color: $shade-white;
	display: flex;
	flex-direction: column;
	gap: rem(24);
	padding: rem(32) rem(24);

	@include tablet {
        max-width: rem(1456);
    }

	h1 {
		margin: 0;
		line-height: 130%;
	}
}

.markdown {
	color: $primary-midnight-700;
	max-width: rem(817);
}

.buttons {
	margin-top: rem(8);
	display: flex;
	flex-direction: column;
	gap: rem(8);

	a {
		width: 100%;
	}

	@include tablet {
		flex-direction: row;

		a {
			width: initial;
		}
	}
}
