@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.eventsCarouselWrapper {
	max-width: 1456px;
	margin: 64px 16px 0;

	@media (min-width: calc(1457px + 64px)) {
		margin: 64px auto;
		width: 1456px;
	}

	.titleArea {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-bottom: 16px;

		@include mobile-sm {
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			margin-bottom: 16px;
		}

		h3 {
			font-size: rem(28);
			margin: 0;
			color: $primary-midnight-900;
			font-weight: 600;
		}

		svg {
			fill: $secondary-steel-900;
			width: 24px;
			height: 24px;
		}

		a {
			color: $secondary-steel-900;
			display: flex;
			align-items: center;
			font-weight: 600;

			&:hover {
				text-decoration: underline;
			}

			&:focus {
				color: $primary-midnight-900;
			}

			&:active {
				outline: 2px solid $information-300;
			}
		}
	}
	:global {
		.swiper {
			.swiper-slide {
				width: fit-content;
				max-width: 265px;
				height: auto;

				@include mobile-sm {
					max-width: 350px;
				}
			}

			.swiper-wrapper {
				padding: 16px 0 24px 0;
			}

			.swiper-scrollbar.swiper-scrollbar-horizontal {
                @include swiperScrollBar;
			}
		}
	}

	.events {
		.eventWrapper {
			display: flex;
			flex-direction: column;
			height: 100%;
			background-color: $shade-white;
		}

		img {
			aspect-ratio: 1 / 1;
			width: 100%;
		}
	}
}

.buttonPlacement--next {
	position: absolute;
	top: 50%;
	right: 20px;
	z-index: 2;
	transform: translate(0, -50%);
}

.buttonPlacement--previous {
	position: absolute;
	top: 50%;
	left: 20px;
	z-index: 2;
	transform: translate(0, -50%);
}

.details {
	display: flex;
	flex-direction: column;
	padding: rem(16);
	gap: rem(16);
    flex: 1;

	h4 {
		font-size: rem(19);
		color: $primary-midnight-900;
        line-height: 1;
	}

	& .price {
		color: $primary-midnight-900;
		margin: 0;
        margin-top: auto;
		font-size: rem(16);
		line-height: 1;
		font-weight: 500;
	}

	& .dates {
        line-height: 1;
        min-height: rem(19);
		color: $primary-cardinal-900;
		font-size: 13px;
		margin: 0;

		time {
			display: inline;
		}
	}
}
