@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.duelListsSection {
    @include contained(true);
    padding-inline: var(--edge-spacing);
    padding-bottom: rem(64);
    padding-top: rem(64);

    .duelListContent {
        background-color: $shade-white;
        display: flex;
        gap: rem(24);
        flex-direction: column;
        padding: rem(64) 0;

        @include tablet {
            flex-direction: row;
            padding: rem(64);
        }

        h2 {
            line-height: 150%;
        }

        h3 {
            margin: 0 0 rem(32);
            color: $shade-white;
        }

        .duelList {
            background-color: $secondary-steel-900;
            padding: rem(32);
            flex: 1;
            width: 100%;
        }

        .duelListMainTitle {
            padding: 0 rem(24) rem(12);
            max-width: rem(300);
            font-weight: 600;
            color: $primary-midnight-900;
            
            @include tablet {
                padding: 0 rem(40) 0 0;
            }
        }

        .duelListItems {
            display: flex;
            flex-direction: column;
            gap: rem(24);

            p {
                line-height: 1;
                color: $shade-white;
                margin: 0;
            }
        }
    }
}