@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.container {
    background-color: $primary-fog-50;
}

.innerWrapper {
    padding: rem(55) rem(24);
    display: flex;
    flex-direction: column;
    max-width: rem(1456);

    @include tablet {
        flex-direction: column;
        padding: rem(64) rem(24);
        gap: rem(32);
        margin: 0 auto;
    }

    @media (min-width: 1000px) {
        flex-direction: row;
        gap: rem(96);
    }

    @media (min-width: 1500px) {
        padding-left: 0;
        padding-right: 0;
    }

    h6 {
        font-size: rem(28);
        font-weight: 600;
        line-height: 150%;
        color: $primary-midnight-900;
        margin-bottom: rem(18);

        @include tablet {
            margin-top: rem(-10);
        }
    }
}

.content {
    @include tablet {
        max-width: rem(406);
    }
}

.markdown {
    margin-bottom: rem(24);

    @include tablet {
        margin-bottom: rem(32);
    }

    p {
        font-size: rem(16);
        color: $primary-midnight-700;
        margin: 0;
    }
}

.imagesWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(20);


    @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(24);
    }

    img {
        width: 100%;
    }
}

.imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $shade-white;
}

.text {
    padding: rem(10) rem(32);
    font-size: rem(19);
    line-height: 120%;
    font-weight: 700;
    color: $primary-midnight-900;
}