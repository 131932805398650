@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';


.categoryWrapper {
	@include contained;
	gap: rem(32);
	padding: rem(64) rem(16);

	@include tablet {
		width: 100%;
	}

	& .title {
		color: $primary-midnight-900;
		margin: 0 0 rem(24);
		font-size: rem(23);
		font-weight: 600;

		@include mobile-sm {
			margin: 0 0 rem(32);
			font-size: rem(28);
		}
	}

	.swiperArea {
		display: flex;
		justify-content: center;
		width: auto;
	}

	& .slide {
		& .image {
			width: 100%;

			svg {
				width: 100%;
				height: 100%;
			}
		}

		& .categoryTitle {
			padding: 16px;
			margin: 0;
			font-size: rem(16);
			color: $primary-midnight-900;
		}
	}

	:global {
		.swiper {
			display: flex;
			flex-direction: column;
			gap: rem(24);
			width: 100%;

			& .swiper-slide {
				background-color: $shade-white;
			}

			& .swiper-button-disabled {
				opacity: 0;
			}

			& .swiper-scrollbar {
                @include swiperScrollBar;
                position: relative;
			}
		}
	}
}
