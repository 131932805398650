@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.contentSection {
	@include tablet {
		@include contained;
	}
	
	&.ecomMargins {
		margin: rem(64) 0 0;
		@include tablet {
			margin: rem(64) auto;
		}
	}

	&.corpMargins {
		margin: rem(64) 0 0;
		@include tablet {
			margin: rem(96) auto 0;
		}
	}

	&.bottomMargins {
		margin: 0;
		@include tablet {
			margin: 0 auto rem(96);
		}
	}
}
.contentWrapper {
	background: $shade-white;
	display: flex;
	padding: rem(40) rem(32);
	flex-direction: column;

	@include tablet {
		flex-direction: row;
		padding: rem(64);
	}

	&.switchOrder {
		flex-direction: column;

		@include tablet {
			flex-direction: row-reverse;
		}

		.imageArea {
			margin-left: 0;

			@include tablet {
				margin-left: 64px;
			}
		}

		.textArea {
			margin-top: 20px;
			padding: 0;

			@include tablet {
				margin: 0;
			}
		}
	}
}

.textArea {
	width: 100%;
	max-width: 632px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: rem(20);
	word-wrap: break-word;

	@include tablet {
		margin: 0 0 0 64px;
		width: 50%;
	}

	.logo {
		width: fit-content;
		margin: 16px 0 32px;

		@include tablet {
			margin: 0 0 44px;
		}

	}

	h2 {
		margin: 0;
		color: $primary-midnight-900;
	}

	.description {
		line-height: 150%;
        margin-top: rem(32);

        @include tablet {
            font-size: rem(19);
        }
	}

	p {
		margin: rem(20) 0 0;
		color: $primary-midnight-700;
	}

	h5 {
		margin: rem(20) 0 0;
		font-size: 16px;
		font-weight: 400;
		color: $primary-midnight-700;
		line-height: 150%;
	}


	button,
	a {
		width: 100%;
		justify-content: center;
		margin: rem(4) 0 rem(4) 0;

		@include tablet {
			width: fit-content;
		}	
	}
	
}

.imageArea {
	width: 100%;

	@include tablet {
		width: 50%;
		max-width: 632px;
	}

	img {
		width: 100%;
		aspect-ratio: 1/1;
	}

	.imageCurveTop {
		border-radius: rem(64) 0 0 0;

		@include mobile-sm {
			border-radius: rem(128) 0 0 0;
		}
	}

	.imageCurveBottom {
		border-radius: 0 0 rem(64) 0;

		@include mobile-sm {
			border-radius: 0 0 rem(128) 0;
		}
	}

	.imageCurveTopBottom {
		border-radius: rem(64) 0 rem(64) 0;

		@include mobile-sm {
			border-radius: rem(128) 0 rem(128) 0;
		}
	}
}

.buttons {
	margin-top: rem(20);
	display: flex;
	flex-direction: column;
	gap: rem(8);

	a {
		width: 100%;
	}

	@include tablet {
		flex-direction: row;

		a {
			width: initial;
		}
	}
}
