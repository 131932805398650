@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
@mixin variant($variant) {
	border-radius: rem(4);
	border: 1px solid $primary-fog-900;

	& .closeToast {
		all: unset;
		cursor: pointer;
		text-decoration: underline;
		margin-left: rem(32);
		margin-top: rem(16);
	}

	@if $variant == "info" {
		border-color: $information-700;
		color: $information-700;
		background-color: $information-50;

		& .closeToast {
			&:hover {
				color: $information-900;
			}
		}
	}

	@if $variant == "success" {
		border-color: $success-700;
		color: $success-700;
		background-color: $success-50;

		& .closeToast {
			&:hover {
				color: $success-900;
			}
		}
	}

	@if $variant == "warning" {
		border-color: $warning-700;
		color: $warning-700;
		background-color: $warning-50;

		& .closeToast {
			&:hover {
				color: $warning-900;
			}
		}
	}

	@if $variant == "error" {
		border-color: $error-700;
		color: $error-700;
		background-color: $error-50;

		& .closeToast {
			&:hover {
				color: $error-900;
			}
		}
	}
}

.info {
	@include variant("info");
}

.warning {
	@include variant("warning");
}

.success {
	@include variant("success");
}

.error {
	@include variant("error");
}

.content {
	display: flex;
	flex-direction: column;
	gap: rem(12);
	font-size: rem(16);
	font-weight: 400;

	p {
		margin: 0;
	}

	& .title {
		font-weight: 700;
	}
}

.container {
	display: flex;
	flex-direction: row;
	gap: rem(8);
	width: 100%;

	svg {
		width: rem(24);
		height: rem(24);
		flex-shrink: 0;
	}
}

.notification {
	max-width: unset !important;
	width: 100%;
	padding: rem(24) rem(16);
	margin-bottom: rem(32);

	border-radius: rem(4);
	border: 1px solid $primary-fog-900;

	p {
		margin: 0;
	}
}

.toastRoot {
	border-radius: rem(6);
	box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	padding: rem(15);
	display: grid;
	grid-template-areas: "title action" "description action";
	grid-template-columns: auto max-content;

	display: flex;
	flex-direction: column;

	max-width: rem(328);

	&[data-state="open"] {
		animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
	}
	&[data-state="closed"] {
		animation: hide 100ms ease-in;
	}

	&[data-swipe="move"] {
		transform: translateX(var(--radix-toast-swipe-move-x));
	}
	&[data-swipe="cancel"] {
		transform: translateX(0);
		transition: transform 200ms ease-out;
	}
	&[data-swipe="end"] {
		animation: swipeOut 100ms ease-out;
	}

	@include tablet {
		max-width: rem(944);
	}
}

@keyframes hide {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slideIn {
	from {
		transform: translateX(calc(100% + var(--viewport-padding)));
	}
	to {
		transform: translateX(0);
	}
}

@keyframes swipeOut {
	from {
		transform: translateX(var(--radix-toast-swipe-end-x));
	}
	to {
		transform: translateX(calc(100% + var(--viewport-padding)));
	}
}
