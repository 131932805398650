@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.spacer {
	height: rem(20);
}

.message {
	display: flex;
	align-items: center;
	gap: rem(8);
	@include paragraph03;
	color: $secondary-steel-900;
	line-height: 1;
	font-weight: 600;

	& svg {
		height: rem(20);
	}
}

.icon {
	height: rem(24);
	aspect-ratio: 1;
}

.trigger {
	border: none;
	background: none;
	display: flex;
	align-items: center;
	color: $secondary-steel-900;
	gap: rem(8);
	cursor: pointer;
}

.popoverContent {
	max-width: rem(348);
	width: 100%;
	padding: rem(24) rem(16) rem(20);
	background-color: white;
	box-shadow: rem(2) rem(2) rem(6) 0 rgba(0, 0, 0, 0.15);
	z-index: 2;

	& .popoverContentInner {
		display: flex;
		flex-direction: column;
		gap: rem(12);

		h4 {
			font-size: rem(16);
			font-weight: 600;
			color: $primary-midnight-900;
		}

		p {
			margin: 0;
			font-size: rem(16);
			font-weight: 400;
			color: $primary-midnight-700;
		}
	}
}

.popoverTrigger {
	all: unset;
}

.popoverClose {
	all: unset;
	border-radius: 100%;
	height: rem(32);
	width: rem(32);
	position: absolute;
	top: rem(5);
	right: rem(5);
	cursor: pointer;
	color: $primary-midnight-500;

	&:hover {
		color: $primary-cardinal-900;
	}

	&:focus-within {
		outline: rem(2) solid $information-300;
	}
}
