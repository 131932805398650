@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.contentSection {
	@include contained;
    display: flex;
    justify-content: center;

	@include tablet {
		margin: 64px auto;
		padding-inline: var(--edge-spacing);
	}

    .imgContainer {
     
        min-width: rem(128);
    
        @include tablet {
            width: 100%;
            min-width: rem(256);
        }
    
        img {
            width: 100%;
        }
    }
}