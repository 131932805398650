@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.contentSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: rem(28);
	padding: rem(64) rem(24);
	background-color: $secondary-steel-300;

	@include tablet {
		padding-left: 0;
		padding-right: 0;
	}

	& .title {
		@include h2;
		font-weight: 600;
		text-align: center;
		padding: 0 rem(30);
		word-break: break-word;
	}

	p {
		color: $shade-white;
		@include paragraph02;
	}
}

.uspsWrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	row-gap: rem(32);
	width: 100%;

	@include mobile {
		column-gap: rem(32);
		max-width: rem(1328);
	}
}

.card {
	position: relative;
	height: rem(374);
    width: 100%;
	transform-style: preserve-3d;

    @include tablet {
        max-width: rem(240);
		height: rem(300);
    }

	&:hover {
		.front {
			transform: rotateY(180deg);
		}

		.back {
			transform: rotateY(0deg);
		}
	}

	.icon {
		position: absolute;
		top: rem(8);
		right: rem(8);
		height:rem(24);
		width:rem(24);
	}

	.back {
		position: absolute;
		background-color: $secondary-steel-900;
		color: $shade-white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: rem(32) rem(24);
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		transform: rotateY(180deg);
		transition: all 0.5s ease;

		h4 {
			width: 100%;
			color: $shade-white;
			font-size: rem(19);
			font-weight: 700;
			line-height: 150%;
			padding: 0px;
		}
	}

	.front {
		position: absolute;
		background-color: $shade-white;
		display: flex;
		flex-direction: column;
		justify-content: center;
        align-items: center;
		text-align: center;
        gap: 10px;
		height: 100%;
        width: 100%;
		padding: rem(32) rem(24);
		backface-visibility: hidden;
		transition: all 0.5s ease;

		h4 {
			color: $primary-midnight-900;
			padding: 0 rem(16);
		}

		.image {
			width: 100%;
            height: 100%;
			
            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
		}
	}
}
