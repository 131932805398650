@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.brandCarousel {
	padding: rem(64) 24px;

	@include desktop-xl {
		padding: rem(64) 0;
	}

	.brandCarouselWrapper {
		@include contained;
		display: flex;
		flex-direction: column;
		gap: rem(32);
		overflow: hidden;
	}

	.heading {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		
		@include tablet {
			flex-direction: row;
		}
	
		a {
			@include regularLink;
			display: flex;
			align-items: center;
			margin: rem(8) 0 0;
			
			@include tablet {
				flex-direction: row;
				margin:0;
			}
		}
			
		svg {
            fill: $secondary-steel-900;
            width: 24px;
            height: 24px;
        }
	}

	h3 {
		color: $primary-midnight-900;
		font-size: rem(28);
		font-weight: 600;
		line-height: 150%;
		margin: 0;
		margin-bottom: rem(32);
	}

	:global {
		.swiper {
			display: flex;
			flex-direction: column;
			gap: rem(24);
			width: 100%;

			.swiper-slide {
				display: flex;
				justify-content: center;
				width: fit-content;
				padding: 0 16px;

				& a {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 80px;

					& img {
						max-height: 100%;
					}
				}
			}

			.swiper-wrapper {
				align-items: center;
			}

			.swiper-scrollbar.swiper-scrollbar-horizontal {
                @include swiperScrollBar;
			}
		}
	}

	.buttonPlacement--next {
		position: absolute;
		top: 50%;
		right: 20px;
		z-index: 2;
		transform: translate(0, -50%);
	}

	.buttonPlacement--previous {
		position: absolute;
		top: 50%;
		left: 20px;
		z-index: 2;
		transform: translate(0, -50%);
	}
}

.toggleBackground {
	background-image: url('../../app/assets/images/img/background.png?format=webp');
	background-size: cover;
}

.background {
	background-color: $shade-white;
}