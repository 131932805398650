@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.quantityChanger {
	min-height: rem(48);
	max-height: rem(48);
	display: grid;
	grid-template-columns: 48px minmax(48px, 1fr) 48px;

	button {
		border: none;
		padding: 0;
		display: grid;
		justify-content: center;
		align-items: center;
        outline-offset: -1px; // stops the outline from animating

		background-color: $primary-cardinal-900;

		svg {
			fill: $shade-white;
		}

		&:hover {
			background-color: $shade-white;
			outline: 1px solid $primary-cardinal-900;
            outline-offset: -1px ;
			transition: 300ms ease-in-out;

			svg {
				fill: $primary-cardinal-900;
			}
		}

		&:active {
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			svg {
				fill: $shade-white;
			}
		}

        &[disabled] {
            cursor: not-allowed;
            background-color: $primary-cardinal-300;
            outline: none;
            
            svg {
                fill: $shade-white;
            }
        }
	}

	&.quantityChangerWhite {
		max-width: 190px;
		button {
			border: rem(1) solid $primary-fog-900;
			border-radius: rem(4);
		
			background-color: $shade-white;
	
			svg {
				color: $primary-midnight-900;
			}
	
			&:active {
				background-color: $primary-midnight-900;
				border-color: $primary-midnight-900;
				svg {
					color: $shade-white;
				}
			}

			&:focus {
				border-color: $information-300;
				background-color: $shade-white;
				svg {
					color: $primary-midnight-900;
				}
			}

			&:hover {
				background-color: $secondary-steel-900;
				outline: none;
				transition: 300ms ease-in-out;
				border-color: $secondary-steel-900;
				svg {
					color: $shade-white;
				}
			}
	
			&[disabled] {
				cursor: not-allowed;
				background-color: $shade-white;
				outline: none;
				border-color:$primary-fog-300 ;
				svg {
					color: $primary-midnight-300;
				}
			}
		}
	}

	input {
		text-align: center;
		font-family: inherit;
		font-size: rem(16);
		line-height: rem(19);
		color: $shade-black;
		outline: none;
		background-color: $shade-white;
		border: 1px solid $primary-fog-900;
		border-left: 0;
		border-right: 0;
	}

	/* Works for Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Works for Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.increase {
	border-radius: 0 4px 4px 0 !important;
}

.decrease {
	border-radius: 4px 0 0 4px !important;
}
