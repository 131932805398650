@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.markdown {
    p {
        margin: 0;
        margin-bottom: rem(16);
        font: inherit;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
