@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.quoteSection {
	background-color: $secondary-steel-900;
	padding: 64px 24px;
	position: relative;

	@include tablet {
		padding: 128px 32px;
	}

	@include desktop {
		padding: 128px 72px;
	}
}

.swiper {
	max-width: var(--content-max-width);
	:global {
		.swiper-scrollbar {
			background: $shade-white;
			height: rem(2);
			.swiper-scrollbar-drag {
				background: $primary-cardinal-900;
				height: 101%;
			}

			width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 0%));
			left: var(--swiper-scrollbar-sides-offset, 0%);
		}

		@include tablet {
			.swiper-scrollbar {
				width: calc(80% - 2 * var(--swiper-scrollbar-sides-offset, 0%));
				left: var(--swiper-scrollbar-sides-offset, 10%);
			}
		}

		@include desktop {
			.swiper-scrollbar {
				width: calc(57% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
				left: var(--swiper-scrollbar-sides-offset, 22.5%);
			}
		}
	}
}

.quoteArea {
	margin: 0 auto;
	color: $shade-white;
	width: 100%;

	&.centreAlign {
		text-align: center;
	}

	@include tablet {
		width: 80%;
	}

	@include desktop {
		width: 55%;
	}

	h2 {
		font-size: 28px;
		font-weight: 600;
		margin: 0 0 20px 0;
		color: $shade-white;
		line-height: 1;

		&.centreAlign {
			margin: 0 0 rem(64);
		}
	}

	.quoteText {
		font-size: 23px;
		font-weight: 400;
		margin: 0 0 26px;

		&.centreAlign {
			line-height: 1;
			font-weight: 600;
			margin: 0 0 rem(24);
		}
	}

	.quoteName {
		margin: 0 0 10px;
		line-height: 1;

		&:last-of-type {
			margin: 0 0 32px;
		}
	}

	.quoteCompany {
		font-weight: 400;
		font-size: 16px;
		margin: 0 0 32px;

		&.centreAlign {
			font-size: 19px;
			line-height: 1;
			margin: 0 0 rem(64);
		}
	}
}

.buttonPlacement--next {
	position: absolute;
	top: 50%;
	right: 1px;
	z-index: 2;
	transform: translate(0, -50%);
}

.buttonPlacement--previous {
	position: absolute;
	top: 50%;
	left: 1px;
	z-index: 2;
	transform: translate(0, -50%);
}
