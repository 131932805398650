@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.serviceCenterSection {
    padding: rem(64) 0 0;
    
    @include tablet {
        padding: rem(96) 0;
    }
    
    .serviceCenterWrapper {
        @include tablet {
            @include contained;
        }

    }

    .serviceCenterTitle {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: rem(24);
        margin: 0 rem(24) rem(32) ;

        @include tablet {
            margin: 0 0 rem(32) ;
            flex-direction: row;
        }

        h2 {
            margin: 0;
        }

        button {
            width: 100%;

            @include mobile {
                width: rem(400);
            }
        }
    }

    .serviceCenters {
        display: flex;
        flex-direction: column;
        gap: rem(24);
    }

    .serviceCenter {
        padding: rem(48) rem(24);
        background-color: $shade-white;
        
        @include mobile {
            padding: rem(64);

            &.withReps {
                padding: rem(64) rem(64) rem(48);
            }
        }

        .showContent {
            display: none;
        }

        .servingAreas {
            margin:0 0 rem(24);
        }

        .scText {
            display: flex;
            flex-direction: column;
            gap: rem(32);

            p {
                margin: 0;
            }

            .scEmail {
                @include underlinedLink;
            }

            .markdown {
                a { font-size: 19px;}
            }

            a {
                @include underlinedLink;
            }
        }

    }

    .noServiceCentres {
        margin: rem(32) 0 0;
        text-align: center;
    }

    .representativesSection {
        display: flex;
        flex-direction: column;
        gap: rem(32);
        background-color: $shade-white;
        padding: 0 rem(24) rem(48);

        @include mobile {
            padding: 0 rem(64) rem(64);
        }

        
        .representative {
            display: flex;
            gap: rem(16);
            flex-direction: column;
            
            
            @include mobile {
                gap: rem(32);
                flex-direction: row;
            }

            img {
                max-width: 128px;
            }
    
            .representativeText {
                display: flex;
                flex-direction: column;
                gap: rem(16);
                p {
                    margin: 0;
                    line-height: 1;
                }

                .representativeName {
                    color: $primary-midnight-900;
                }

                .representativeTitle {
                    font-size: 16px;
                }

                a {
                    @include underlinedLink
                }

                .repsServiceCentreList {
                    span:not(:last-child):after {
                        content: ', ';

                    }
                }
            }
        }
    }

    .buttonFindOutMore {
        width: 100%;

        @include mobile {
            width: fit-content;
        }
    }

    .marginTop {
        margin: rem(16) 0 0;
    }
    
}

.trigger {
	display: inline-flex;
	align-items: flex-start;
	justify-content: space-between;
	height: auto;
	width: 100%;
	border: 1px solid $primary-fog-900;
	border-radius: 2px;
	background-color: white;
	color: $primary-midnight-700;
	padding: 8px 12px;
	@include paragraph03;

	&:hover {
		border-color: $primary-midnight-300;
	}

	&:focus-visible {
		outline: 1px solid $information-300;
		border-color: $information-300;
	}

	&:disabled {
		background-color: $primary-fog-300;
		border-color: $primary-fog-900;
		color: $primary-midnight-700;
	}

	&.error {
		border-color: $error-700;
	}

	&[data-placeholder] {
		color: $primary-midnight-700;
	}
}

.item {
    line-height: 150%;
    padding: rem(5);
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.item[data-highlighted] {
    outline: none;
    background-color: $secondary-steel-900;
    color: $shade-white;
}


.arrow {
	height: 24px;
	width: 24px;
	rotate: 90deg;
}

.content {
	overflow: hidden;
	background-color: white;
	border-radius: 2px;
	box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
		0px 10px 20px -15px rgba(22, 23, 24, 0.2);
	max-width: 500px;
    width: fit-content;
	height: auto;
  	z-index: 99999;
    padding: rem(15);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include mobile {
        width: 500px;
    }

}

.scroll {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	cursor: default;
	width: 13px;
	aspect-ratio: 1;

	&.up {
		rotate: 270deg;
	}

	&.down {
		rotate: 90deg;
	}
}