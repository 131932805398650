@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.sixPanelContentSection {
	display: flex;
	flex-direction: column;
	gap: rem(27);
	max-width: 1456px;
	padding: rem(64) 16px 0;
	margin: auto;

	@include desktop {
		gap: rem(64);
		padding: rem(128) 64px 0;
		flex-direction: row;
	}

	@include desktop-xl {
		padding: rem(128) auto;
		width: 1456px;
	}

	& .contentSection {
		display: flex;
		flex-direction: column;
		gap: rem(20);
		flex: 1;

		& .mainTitle {
			color: $primary-midnight-900;
			word-break: break-word;
		}

		& p {
			color: $primary-midnight-700;
			word-break: break-word;
			font-size: rem(16);
			margin-bottom: rem(8);
		}

		& .button {
			width: 100%;
			text-align: center;

			@include mobile-sm {
				width: fit-content;
			}
		}
	}

	& .panelArea {
		display: grid;
		grid-template-columns: 1fr;
		gap: 16px;
		flex: 2;

		@include mobile {
			grid-template-columns: repeat(2, 2fr);
		}

		@include tablet {
			grid-template-columns: repeat(3, 1fr);
		}

		& .panel {
			display: flex;
			flex-direction: column;
			background-color: $shade-white;

			& .image {
				width: 100%;
				min-width: 270px;
				aspect-ratio: 4/3;
				overflow: hidden;
				object-fit: cover;
				flex-shrink: 0;

				& img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}

			& .panelTextArea {
				display: flex;
				flex-direction: column;
				gap: rem(16);
				padding: rem(32);

				> * {
					line-height: 150%;
				}

				& .title {
					color: $primary-midnight-900;
					font-weight: 700;
					margin: 0;
				}

				& .learnMore {
					color: $secondary-steel-900;
					text-decoration: underline;
					font-size: remCalc(16);
					font-weight: 600;

                    @include underlinedLink;
				}
			}
		}
	}
}
