@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.cartButton {
    width: 100% !important;
}

.actions {
    display: flex;
    gap: rem(5)
}

.link {
    @include underlinedLink;
}