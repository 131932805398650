@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.loading {
    margin-top: rem(10);
    padding: rem(30);
}

.listContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.item {
    border: none;
    background-color: none;
    @include underlinedLink;
    font-weight: 400;
    padding: rem(10) 0;
    text-align: left;
    cursor: pointer;
}

.action {
    width: 100%;
    border-top: 1px solid $primary-fog-900;
    margin-top: rem(16);
    padding-top: rem(16);
}

.create {
    width: 100%;
}
