@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.platformsWrapper {
	display: flex;
	background-color: white;
	box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);

	&[data-state="open"] {
		animation-name: slideUpAndFade;
		animation-duration: 0.3s;
	}

	@keyframes slideUpAndFade {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.platform {
		margin: 8px;
        color: $primary-midnight-700;

		&:hover {
			color: $primary-cardinal-900;
		}

		&:active {
            color: $primary-midnight-900;
		}

		&:focus {
			outline: none !important;
			border: 1px solid $information-300;
		}
	}
}

.toggle {
	border: none;
	background-color: white;
	position: relative;
	padding: 0.5rem;
	border-radius: 4px;
    height: 48px;
    width: 48px;
    color: $secondary-steel-900;

	&[data-state="open"] {
		background-color: $secondary-steel-900 !important;
		transition: background-color 0.3s ease;
        color: white;
	}

	&:hover {
		cursor: pointer;
        color: $primary-cardinal-900;
	}
}
