@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.outer {
  background-color: $secondary-steel-300;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: rem(32);
  padding: rem(64) rem(24);

  @include tablet {
    flex-direction: row;
    gap: rem(64);
  }

  & .left {
    display: flex;
    flex-direction: column;

    & .title {
      font-weight: 600;
      margin-top: 0;
      margin-bottom: rem(30);
      min-width: 250px;
      max-width: 350px;
    }

    & .btnLinks {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: rem(12);

      @include tablet {
        flex-direction: column;
      }
    }
  }

  & .right {
    position: relative;
    height: 100%;
    aspect-ratio: 16 / 9;

    & .dogImage {
      height: 100%;
      width: 100%;
    }

    & .dot {
      position: absolute;
      aspect-ratio: 1;
      top: 1px;
      left: 1px;
      height: 15%;

      & svg {
        height: 100%;
        aspect-ratio: 1;

        & > * {
          transition: .3s;
        }
      }

      &.ankle {
        top: 60%;
        left: 6%;
      }

      &.hip {
        top: 35%;
        left: 25%;
      }

      &.elbow {
        top: 55%;
        left: 75%;
      }

      &.knee {
        top: 65%;
        left: 22%;
      }

      &:hover svg {
        & path:first-child {
          fill: white;
        }

        & path:nth-child(2) {
          stroke: $primary-cardinal-900;;
        }

        & path:last-child {
          fill: $primary-cardinal-900;;
        }
      }
    }
  }
}