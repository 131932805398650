@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.commonDetails {
    display: flex;
    flex-direction: column;

    a {
        display: block;
    }
}

.imageContainer {
    width: 100%;
    aspect-ratio: 1 / 1;
    padding: rem(8) rem(8) 0;
    overflow: hidden;
    box-sizing: border-box;

    &.imageContainerPadding {
        padding: unset;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.details {
    padding: rem(20) rem(16);
    overflow-wrap: anywhere;
}

.brandLink {
    @include paragraph02;
    font-weight: 400;
    color: $primary-midnight-700;
    z-index: 99999;
}

.sku {
    @include paragraph03;
    margin: 0;
    margin-bottom: .7rem;
    color: $primary-cardinal-900;
}

.productName {
    @include regularLink;
    @include paragraph01;
    color: $primary-midnight-900;
    font-weight: 700;

    &.ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.specs {
    display: flex;
    flex-direction: column;
    gap: rem(5);
    padding: 0 rem(16) rem(20);

    & .spec {
        display: flex;
        gap: rem(5);
        @include paragraph03;
        color: $primary-midnight-700;

        & .label {
            font-weight: 500;
        }
    }
}
