@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.section {
	display: flex;
	justify-content: center;
	
	@include tablet {
		padding: rem(96) 0;
	}
}

.contentWrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	gap: 0;
	width: 100%;
	min-height: 200px;
	margin: 0 auto 0;

	@include tablet {
		@include contained;
	}
	

	h2 {
		margin-bottom: rem(48);
		line-height: 150%;
		font-weight: 700;
		font-size: rem(28);
	}

	h3 {
		font-weight: 600;
		font-size: rem(23);
		margin-bottom: rem(32);
	}

	p {
		@include paragraph02;
		margin-bottom: rem(48);
	}

	> div {
		padding: rem(64) rem(24);

		@include tablet {
			padding: rem(64);
		}
		
		
	}

	.contentLeft {
		background-color: $secondary-steel-900;
		color: $shade-white;
		flex: 1;

		h2, h3 {
			color: $shade-white;
		}

		.markdown {
			color: $shade-white;
			max-width: rem(817);
		}
	}

	.contentRight {
		background-color: $shade-white;
		color: $primary-midnight-900;
		flex: 1;
		
		&.input {
			width: 100%;
		}

		.markdown {
			color: $primary-midnight-900;
			max-width: rem(817);
		}
	}
}



