@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.subBannerSection {
	padding-top: rem(64);
	padding-bottom: rem(32);
    @include tablet {
        @include contained;
		padding-top: rem(96);
    }

    .subBanner {
        padding: rem(64) rem(24);
        position: relative;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

        @include tablet {
            padding: rem(64) rem(72);
        }

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 85%;
		}


		&.backgroundColor {
			&::after {
				background-color: #0c436ad9;
			}	
		}

        .subBannerText {
			z-index: 2;
			position: relative;
			padding: 0;
            width: 100%;

            @include tablet {
                width: 54%;
            }

			h2 {
				color: $shade-white;
				font-weight: 600;
			}
			.markdown {
				color: $shade-white;
				margin: rem(20) 0 0;
                
                p {
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                }
			}
		}
    }
}