@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.solutionsCarousel {
    @include contained;
    .solution {
        background-color: $shade-white;
        display: flex;
        flex-direction: column;

        @include mobile-sm {
            max-width: rem(350);
        }

        img {
            width: calc(100% - 8px);
        }

		.imageCurveTop {
			border-radius: 72px 0 0 0;
		}
	
		.imageCurveBottom {
			border-radius: 0 0 72px 0;
		}

		.imageCurveTopBottom {
			border-radius: rem(72) 0 rem(72) 0;
		}

        .textArea {
            padding: rem(32);
            display: flex;
            flex-direction: column;
            flex: 1;

            h2 {
                font-size:19px;
                font-weight: 700;
                color: $primary-midnight-900;
            }

            .markdown {
                margin: rem(24) 0 0;
                font-size: 13px;
                color: $primary-midnight-700;
                flex: 1;
            }

            button, a {
                margin: rem(32) 0 0;
            }
        }
    }

    :global {
		.swiper {
			display: flex;
			flex-direction: column;
			gap: rem(24);
			width: 100%;

			.swiper-slide {
				display: flex;
				justify-content: center;
				width: fit-content;
                height: auto;
			}

			.swiper-scrollbar.swiper-scrollbar-horizontal {
				width: 100%;
				left: 0;
				height: 2px;
				bottom: 0px;

				.swiper-scrollbar-drag {
					background-color: $primary-cardinal-900;
				}
			}
		}
	}

	.buttonPlacement--next {
		position: absolute;
		top: 50%;
		right: 20px;
		z-index: 2;
		transform: translate(0, -50%);
	}

	.buttonPlacement--previous {
		position: absolute;
		top: 50%;
		left: 20px;
		z-index: 2;
		transform: translate(0, -50%);
	}
}