@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.contentWrapper {
	@include contained;
	display: flex;
	flex-direction: column;
	// gap: rem(32);
	// background-color: inherit;

	@include tablet {
        padding: rem(24) rem(64);
        max-width: rem(1456);
        margin: 0 auto;
    }

	& .header {
        & .title {
			color: $primary-midnight-900;
			margin: 0;
			@include h2;
			font-weight: 600;
		}
    }

    & .body {
        width: 100%;
    }

	.markdown {
		color: $primary-midnight-700;
		
		p {
			@include paragraph02;
			margin: rem(23) 0 !important;
		}

		h3 {
			@include h3;
			font-weight: 600;
		}
	}
}

ul.navList {
	padding-left: 0;
	list-style: none;
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;
	@include paragraph02;

	li {
		display: flex;
		place-items: center;
		padding: rem(8);
		cursor: pointer;
		border-bottom: rem(4) solid transparent;

		&:hover {
			text-decoration: none;
			border-bottom: rem(4) solid $primary-cardinal-900;
		  }

		&.active {
			text-decoration: none;
			border-bottom: rem(4) solid $secondary-cloud-900;
		  }
	}
}
