@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.formField {
	display: flex;
	flex-direction: column;
	gap: rem(8);
	font-family: inherit;

	& .label {
		@include paragraph02;
		font-weight: 600;
		color: $primary-midnight-900;
	}

	& .errorMessage {
		@include paragraph03;
		color: $error-700;
	}

	& .helpText {
		@include paragraph03;
		color: $primary-midnight-700;
	}
}

.asterisk {
	color: $error-700;
}
