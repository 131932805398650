@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.imageContainer {
  height: 100%;
  width: 100%;

  & img {
    height: 100%;
    width: 100%;
  }
}
