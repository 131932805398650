@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.triContentBlockSection {

    @include tablet {
        @include contained;
        padding: rem(96) var(--edge-spacing);
    }

    .blocks {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include tablet {
            flex-direction: row;
        }
    }

    .whiteBlock {
        background-color: $shade-white;
        padding: rem(64) rem(32);
        color: $primary-midnight-900;
        display: flex;
        align-items: center;

        .whiteBlockText {
            padding: 0;

            @include tablet {
                padding: 0 rem(64);
            }

            .markdown {
                p {
                    font-size: rem(19);
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                    margin: 0 0 rem(32);

                    @include tablet {
                        font-size: rem(23);
                    }
                }
                
                a {
                    @include underlinedLink;
                }
			}
        }

        @include tablet {
            padding: rem(96) rem(72);
        }
    }

    .rightSide {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .rightBlock {
            padding: rem(64) rem(32);
            color: $shade-white;
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 85%;
            }

            &.backgroundColor {
                &::after {
                    content: '';
                    background-color: #0c436ad9;
                }	
            }

            @include tablet {
                padding: rem(96) rem(72);
            }
        }

        .blockText {
			z-index: 2;
			position: relative;
			padding: 0;

            @include tablet {
                padding: 0 rem(64);
            }

			h2 {
				color: $shade-white;
				font-weight: 600;
			}
			.markdown {
				color: $shade-white;
				margin: rem(10) 0 0;
                
                p {
                    font-size: rem(19);
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                }

                a {
                    @include underlinedLink;
                }
			}
        }

    }
}