@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.section {
	@include contained;
	padding-block: rem(64);
	:global {
		.swiper {
			display: flex;
			flex-direction: column;
			gap: rem(24);
			width: 100%;

			& .swiper-button-disabled {
				opacity: 0;
			}

			& .swiper-scrollbar {
				@include swiperScrollBar;
				position: relative;
			}
		}
	}
}

.title {
	margin-bottom: rem(24);
	font-size: rem(23);
	font-weight: 600;

	@include tablet {
		margin-bottom: rem(32);
		font-size: rem(28);
	}
}

.card {
	display: flex;
	flex-direction: column;
	gap: rem(8);
	background-color: $primary-fog-50;
	height: 100%;
	width: 100%;

	& .inner {
		background-color: white;
		margin: 0;
		padding: 1rem;
		flex: 1;
		display: flex;
		flex-direction: column;

		& .pricingContainer,
		& .login {
			margin-top: auto;
		}

		& .login {
			@include underlinedLink;
		}
	}
}

.slide {
	//max-width: rem(348);
	height: auto;
}

.mini {
	padding: rem(24) rem(16);

	:global {
		.swiper {
			display: flex;
			flex-direction: column;
			gap: rem(24);
			width: 100%;

			& .swiper-button-disabled {
				opacity: 0;
			}

			& .swiper-scrollbar {
				@include swiperScrollBar;
				position: relative;
			}
		}
	}

	& .slide {
		max-width: rem(483);
		height: auto;
	}

	& .title {
		margin-bottom: rem(16);
		font-size: rem(16);
		font-weight: 400;
	}

	& .card {
		& .inner {
			flex-direction: row;
			gap: rem(16);
		}

		& .details {
			display: flex;
			flex-direction: column;
			gap: rem(8);
			& .brand {
				@include regularLink;
				font-weight: 400;
				color: $primary-midnight-700;
				font-size: rem(13);
				font-weight: 400;
				flex: 1;
			}

			& .name {
				@include regularLink;
				color: $primary-midnight-900;
				font-size: rem(13);
				font-weight: 400;
			}

			& .price {
				color: $primary-midnight-900;
				font-size: rem(13);
				font-weight: 400;
			}
		}

		& .ctaDesktop {
			display: none;

			@include tablet {
				display: block;
				margin: auto;
				button {
					width: rem(22);
					height: rem(22);
					border-radius: 0.25rem;
				}
			}
		}

		& .ctaMobile {
			padding: rem(0) rem(16) rem(16);

			button {
				width: 100%;
			}
			@include tablet {
				display: none;
			}
		}

		img {
			width: rem(64);
			height: rem(64);
			aspect-ratio: 64 / 64;
			border: 1px solid $primary-fog-900;
		}
	}
}
