@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.representativesSection {
    background-color: $secondary-steel-900;
	padding: 64px 24px;
	position: relative;

	@include tablet {
		padding: 64px 32px;
	}

	@include desktop {
		padding: 96px 72px;
	}

    .representativesWrapper {
        max-width: var(--content-max-width);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: rem(64);

        @include tablet {
            gap: rem(96);
        }

        div {
            color: white;
        }
    }

    .representativeText {
        display: flex;
        flex-direction: column;
        gap: rem(24);
        flex: 2;


        p,
        .markdown {
            color: $shade-white;
            margin: 0;
            word-break:break-word;
        }

        .markdown p {
            @include h3;
        }

        a p {
           text-decoration: underline;
        }

        .name {
            font-weight: 600;
            line-height: 1;
        }

        .job {
            margin: rem(16) 0 0;
            @include paragraph02;
            line-height: 1;
        }

        .phone {
            margin: rem(12) 0 0;
            line-height: 1;
        }
    }

    .mainRepresentative {
        display: flex;
        flex-direction: column;
        gap: rem(36);
        padding: 0;
        align-items: center;

        @media (min-width: 1000px) {
            padding:  0 rem(72);
            gap: rem(96);
        }

        @include tablet {
            flex-direction: row;
        }
        
        img {
            max-width: 400px;
            width: 100%;
            flex: 1;
        }

        div {
            flex: 1;
        }

    }
    .otherRepresentatives {
        padding:  0;

        @media (min-width: 1000px) {
            padding:  0 rem(72);
            gap: rem(96);
        }

        h3 {
            color: $shade-white;
            margin: 0 0 rem(32);
        }
        
        .representatives {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: rem(32);

            @include tablet {
                flex-direction: row;
            }
        }

        .otherRep {
            display: flex;
            gap: rem(12);
            min-width: 45%;
            flex-direction: column;
            flex: 1;

            @include mobile {
                flex-direction: row;
                gap: rem(32);
                align-items: center;
            }
        }

        img {
            max-width: 128px;
            width: 100%;
        }
    }
}