@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.btn {
	height: 100%;
	aspect-ratio: 1;
	padding: rem(12);
	border: solid 1px transparent;
	border-radius: rem(4);
	font-size: rem(16);
	font-weight: 600;
	display: grid;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	cursor: pointer;

	& svg {
		height: rem(24);
		width: rem(24);
	}

	&:hover {
		transition: background-color 300ms ease-in-out;
	}

	&.primary {
		background-color: $primary-cardinal-900;
		border-radius: rem(4) 0 0 rem(4);
		color: $shade-white;

		&:hover {
			background-color: $shade-white;
			border-color: $primary-cardinal-900;
			color: $primary-cardinal-900 !important;
		}

		&:active,
		&.active {
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
			color: $shade-white;
		}

		&:focus,
		&:focus-within,
		&:focus-visible {
			background-color: $primary-cardinal-900;
			border-color: $information-300;
			outline: 2px solid $information-300;
			color: $shade-white;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			background-color: $primary-cardinal-300;
			border-color: $primary-cardinal-300;
			color: $shade-white;
		}
	}

	&.secondary {
		border-color: $primary-fog-300;
		background-color: $primary-fog-300;
		border-radius: rem(4) rem(4);
		color: $primary-midnight-900;

		&:hover {
			background-color: $secondary-steel-900;
			color: $shade-white !important;
		}

		&:active,
		&.active {
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
			color: $shade-white;
		}

		&:focus-visible {
			background-color: $shade-white;
			border-color: $information-300;
			outline: 2px solid $information-300;
			color: $primary-midnight-900;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			border-color: $primary-fog-300;
			background-color: $primary-fog-300;
			color: $primary-midnight-300;
		}
	}

	&.tertiary {
		background-color: transparent;
		font-weight: 400;
		color: $secondary-steel-500;

		&:hover {
			background-color: $secondary-steel-900;
			color: $shade-white !important;
		}

		&:active,
		&.active {
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
			color: $shade-white;
		}

		&:focus,
		&:focus-within,
		&:focus-visible {
			border-color: $information-300;
			outline: 2px solid $information-300;
			color: $primary-midnight-900;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			background-color: transparent;
			border-color: $primary-fog-300;
			color: $secondary-steel-300;
		}
	}

	&.empty {
		background-color: transparent;
		border: none;
		padding: 0;
		margin: 0;
		min-height: unset;
		color: inherit;

		&:focus,
		&:focus-within,
		&:focus-visible {
			border-color: $information-300;
			outline: 2px solid $information-300;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
		}
	}

}
