@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.headerLeftContentMainWrapper {
    margin-block: rem(64);
    
    @include tablet {
        margin-block: rem(96);
        @include contained;
    }

}
.headerLeftContent {
    background-color: $shade-white;
    padding: rem(64) rem(24);
    
    @include tablet {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 64px;
        padding: 64px;
    }
}

.titleWrapper {
    margin-bottom: 32px;

    & > * {
        font-size: 1.75em;
        line-height: 1.5;
        
        // @include tablet-xl {
        //     margin-left: 4rem;
        // }
    }
}

.mainContentWrapper {
line-height: 150%;
    ol li:not(:last-child), ul li:not(:last-child) {
        margin-bottom: rem(32);
      }

    @include tablet-xl {
        flex: 29;
    }
}

.customMarkdownStyle {
    p {
        font-size: 1em;
        color: $primary-midnight-700;
        line-height: 1.5;
        margin-bottom: 2rem;
    }

    strong {
        font-size: 1.25em;
        color: $primary-midnight-900;
    }

    a {
        @include regularLink;
    }
    
}