@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.card {
	display: flex;
	flex-direction: column;
	gap: rem(8);
	padding: rem(8);
	background-color: $primary-fog-50;
	height: 100%;

	&.inCart {
		background-color: $secondary-moss-500;
	}
}

.inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
	margin: 0;
	height: 100%;
}

.commonDetails {
	display: flex;
	flex-direction: column;

	a {
		display: block;
	}
}

.imageContainer {
	width: 100%;
	aspect-ratio: 1 / 1;
	padding: rem(5);

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.details {
	padding: rem(15) rem(16);
	overflow-wrap: anywhere;
}

.brandLink {
	@include regularLink;
	font-weight: 400;
	color: $primary-midnight-700;
}

.sku {
	margin: 0;
	margin-bottom: 0.7rem;
	font-size: 0.7rem;
	color: $primary-cardinal-900;
}

.productName {
	@include regularLink;
	@include paragraph01;
	color: $primary-midnight-900;
	font-weight: 700;
}

.loginLink {
	@include regularLink;
	margin-top: auto;
}

.noPricing {
	padding: 0 rem(10) rem(15);
}

.pricing {
	display: flex;
	flex-direction: column;
	gap: rem(10);
	padding: 0 rem(16) rem(20);
}

.noStock {
	height: rem(24);
}
