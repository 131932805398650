@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.contentWrapper {
	@include contained;
	display: flex;
	flex-direction: column;
	gap: rem(32);
	padding: rem(64) rem(16);

	@include tablet {
		width: 100%;
	}

	& .contentPDPWrapper {
		margin: 0;
		
		@include tablet {
			padding: rem(64) rem(72);
		}
	}

	& .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        & .title {
			color: $primary-midnight-900;
			margin: 0;
			@include h2;
			font-weight: 600;
		}

        & .link {
            display: flex;
            align-items: center;
            gap: rem(5);
            @include regularLink;

            svg {
                height: rem(20);
                aspect-ratio: 1;
            }
        }
    }

	& .slide {
		& .image {
			width: 100%;
		}

		.imageCurveTop {
			border-radius: rem(64) 0 0 0;
		}
	
		.imageCurveBottom {
			border-radius: 0 0 rem(64) 0;
		}
	
		.imageCurveTopBottom {
			border-radius: rem(64) 0 rem(64) 0;
		}

		& .contentBlock {
			padding: rem(32);
		}

		& .contentTitle {
			margin: 0 0 rem(24) 0;
			padding: 0;
			@include h4;
			font-weight: 700;
			color: $primary-midnight-900;
		}

		& .description {
			@include paragraph02;
			text-decoration: none;
			color: $primary-midnight-700;
		}
	}

	:global {
		.swiper {
			display: flex;
			flex-direction: column;
			gap: rem(24);
			width: 100%;

			& .swiper-slide {
				background-color: $shade-white;
			}

			& .swiper-button-disabled {
				opacity: 0;
			}

			& .swiper-scrollbar {
                @include swiperScrollBar;
                position: relative;
			}
		}
	}

	& .buttonPlacement--next,
	.buttonPlacement--previous {
		position: absolute;
		top: 50%;
		right: 20px;
		z-index: 2;
		transform: translate(0, -50%);
	}

	& .buttonPlacement--previous {
		right: unset;
		left: 20px;
	}
}
