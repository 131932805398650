@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.section {
	padding: rem(64) 0;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 64px 0px;
	align-items: center;

	@include tablet {
		flex-direction: row;
		justify-content: center;
		align-items: unset;
	}

	.contentBox {
		background-color: $shade-white;
		flex: 1;
		width: 100%;
		display: flex;
		flex-direction: column;

		img {

			@include mobile-sm {
				padding-right: 8px;
			}
		}

		@include tablet {
			max-width: 50%;
		}
	}

	.contentText {
		display: flex;
		flex-direction: column;
		padding: rem(32);

		h3 {
			margin: 0 0 24px 0;
		}
		p {
			margin: 0 0 32px 0;
			color: $primary-midnight-700;
		}
	}

	.bottomRightCurve {
		border-radius: 0px 0px 64px 0px;
		width: 100%;
	}
}

.button {
	margin-top: auto;
}