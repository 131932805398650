@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.pricingContainer {
    display: flex;
	align-items: flex-end;
	gap: rem(8);
	@include paragraph02;
	font-weight: 600;
	color: $primary-midnight-900;
}

.oldPrice {
	@include paragraph03;
	color: $primary-midnight-700;
}

.range {
	display: flex;
	align-items: center;
	gap: rem(5)
}

.link {
	@include regularLink;
	@include paragraph02;
	font-weight: 600;
	width: fit-content;
}
