@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.container {
    @include contained;
    background-color: $primary-fog-50;
    display: flex;
    justify-content: center;
}

.innerWrapper {
    display: flex;
    flex-direction: column;
    padding: rem(52) 0;

    @include tablet {
        flex-direction: row;
        padding-top: rem(96);
        padding-bottom: rem(96);
        column-gap: rem(64);
        width: 100%;
    }

    @include desktop-xl {
        padding-left: 0;
        padding-right: 0;
    }
}

.content {
    @include tablet {
        max-width: rem(400);
    }

    h6 {
        font-size: rem(28);
        font-weight: 600;
        margin-bottom: rem(14);
        color: $primary-midnight-900;
        line-height: 150%;

        @include tablet {
            margin-bottom: rem(28);
        }
    }

    .markdown p {
        font-size: rem(16);
        color: $primary-midnight-700;
        margin-bottom: rem(28);

        @include tablet {
            margin-bottom: 0;
        }
    }
}

.imagesWrapper {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    justify-content: center;
    height: fit-content;

    @include mobile {
        flex-direction: row;
    }

    @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: rem(16);
        width: 100%;
    }

    .imgContainer {
        width: 100%;
        background-color: $shade-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    img {
        width: 100%;
        object-fit: contain;
    }

    .text {
        font-size: rem(19);
        font-weight: 700;
        line-height: 120%;
        text-align: center;
        color: $primary-midnight-900;
        margin: 0;
        padding: rem(24) rem(32);
    }
}