@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.contentWrapper {
    display: flex;
    flex-direction: column;
    padding: rem(64) rem(24);
    gap: rem(60);
    background-color: $shade-white;

    @include tablet {
        flex-direction: row;
        padding: rem(60) rem(64);
        max-width: rem(1456);
        margin: 0 auto;
    }

    &.flipped {
        flex-direction: column-reverse;

        @include tablet {
            flex-direction: row-reverse;
        }
    }
}

.content,
.logoContainer {
    @include tablet {
        width: 50%;
    }
}

.markdown {
    margin-bottom: rem(22);

    p {
        font-size: rem(28);
        line-height: 150%;
        color: $primary-midnight-900;
    }
}

.button {
    width: 100%;

    @include tablet {
        width: unset;
    }
}

.logoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(16);
}

.logo {
    min-width: rem(128);
    min-height: rem(128);
    display: flex;
    align-self: center;
    justify-content: center;
    width: calc(50% - 16px);

    img {
        max-width: rem(128);
        object-fit: scale-down;
    }
}