@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.product {
	display: flex;
	flex-direction: column;
	background-color: $primary-fog-50;
    min-width: 170px;
    width: 100%;
    padding: rem(16);
}

.name {
    @include paragraph01;
    font-weight: 600;
    color: $primary-midnight-900;
}

.sku {
    @include paragraph03;
    color: $primary-cardinal-900;
    margin-bottom: rem(8);
}

.specs {
    display: flex;
    flex-direction: column;
    gap: rem(5);
    flex-grow: 1;
    height: fit-content;
    margin-bottom: rem(16);

    .spec {
        display: flex;
        gap: rem(5);
        @include paragraph03;

        & .label {
            font-weight: 500;
        }
    }
}

.price {
    @include paragraph01;
    color: $primary-midnight-900;
    margin-bottom: rem(16);
    font-weight: 600;
}

.actions {
    display: flex;
    gap: rem(8);
}

.wishlistButton {
    width: 100%;
}

.cartButton {
    width: 100%;
    white-space: nowrap;
}

.stock {
    margin-top: rem(10);
    margin-bottom: rem(15);
}
