@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';

.accordionRoot {
	display: flex;
	flex-direction: column;
	gap: rem(8);
}

.heading {
	margin: 0;
	margin-bottom: rem(24);
	font-size: rem(19);
	font-weight: 600;
	line-height: 150%;

	@include tablet {
		font-size: rem(23);
	}
}

.FaqSection {
	display: flex;
	flex-direction: column;
	gap: rem(24);
	margin-bottom: rem(44);
	color: $primary-midnight-900;

	/* reset */
	& button,
	& h3 {
		all: unset;
	}

	& .accordionRoot {
		display: flex;
		flex-direction: column;
		gap: rem(8);
	}

	& .heading {
		margin: 0;
		font-size: rem(19);
		font-weight: 600;
		line-height: 150%;

		@include tablet {
			font-size: rem(23);
		}
	}

	& .AccordionHeader {
		display: flex;
		cursor: pointer;
	}

	& .AccordionTrigger {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $primary-fog-500;
		padding: rem(8) rem(16);
		gap: rem(20);
		font-weight: 600;
        font-size: rem(16);
        line-height: 150%;
        color: $primary-midnight-900;

		& svg {
			fill: $primary-midnight-900;
			transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
			height: rem(32);
			width: rem(32);
		}

        &:hover {
            background-color: $primary-fog-700;

            & svg {
				fill: $primary-midnight-700;
			}
        }

		&[data-state='open'] {
			background-color: $primary-fog-900;

			& svg {
				transform: rotate(90deg);
			}
		}
	}

	& .AccordionContent {
		padding: rem(24) rem(16);
		background-color: $primary-fog-300;

		& .markdown * {
			color: $primary-midnight-900;
			font-size: rem(16);
			font-weight: 400;
			line-height: 150%;

			a {
				color: $secondary-steel-900;
				text-decoration: underline;

				&:hover {
					color: $secondary-steel-500;
				}

				&:active {
					color: $primary-midnight-900;
				}

				&:focus {
					outline: 2px solid $information-300;
				}
			}
		}

	}
}
