@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.buttonWrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: calc(100% - (var(--edge-spacing) * 2));
}

.carouselArrowButtons {
    width: rem(48);
    height: rem(48);
    box-sizing: border-box;
    font-family: inherit;
    border: solid 1px transparent;
    border-radius: 50%;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    background-color: $shade-white;
    outline: none;
    display: none;
    box-shadow: 2px 2px 6px 0 #00000026;

    @include tablet {
        display: block;
    }

    & i {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;

        svg {
            width: 40px;
            height: 40px;
            fill: $primary-cardinal-900;
            color: $primary-cardinal-900;
        }
    }

    &:focus,
    &:focus-within,
    &:focus-visible {
        background-color: $shade-white;
        outline: 2px solid $information-300;
    }

    &:hover {
        transition: 300ms ease-in-out;
        background-color: $primary-cardinal-900;
        outline: none;
        svg {
            fill: $shade-white;
            color: $shade-white;
        }
    }

    &:active,
    .active {
        background-color: $primary-midnight-900;
        transition: none;
        outline: none;
        svg {
            fill: $shade-white;
            color: $shade-white;
        }
    }

    &[disabled],
    &:disabled {
        opacity: 0;
    }
}

.buttonPlacement {
    &--previous {
        z-index: 10;
        position: absolute;
        left: rem(10);
    }

    &--next {
        z-index: 10;
        position: absolute;
        right: rem(10);
    }

}

.buttonYPosition {
    top: 50%;
    transform: translate(0, -50%);
}
