@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.outer {
    background-color: $secondary-steel-300;
}

.container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: rem(32);
    padding: rem(64) rem(24);
    width: 1328px;
    max-width: 100%;
    margin: auto;

    @include tablet {
        flex-direction: row;
        gap: rem(64);
    }

    & .left {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include tablet {
        width:50%;
        }

        & .title {
        font-weight: 600;
        margin-top: 0;
        margin-bottom: rem(30);
        min-width: 250px;
        }

        & .btnLinks {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: rem(12);

        }
    }

    & .right {
        position: relative;
        
        height: 100%;

        @include tablet {
        padding-left:rem(20);
        }

        & .image {
        height: 100%;
        width: 100%;
        }
    }
}
