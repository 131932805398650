@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.input {
	border: rem(1) solid $primary-fog-900;
	border-radius: rem(2);
	background-color: white;
	padding: rem(8) rem(12);
	@include paragraph03;
	color: $primary-midnight-700;
	margin-bottom: 0;
	font-family: inherit;
	width: 100%;

	&::placeholder {
		font-family: inherit;
		font-weight: 500;
	}

	&:hover {
		border-color: $primary-midnight-300;
	}

	&:focus-visible {
		outline: rem(1) solid $information-300;
		border-color: $information-300;
	}

	&:disabled {
		background-color: $primary-fog-300;
		border-color: $primary-fog-900;
		color: $primary-midnight-700;
	}

	&.error {
		border-color: $error-700;
	}
}
