@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.featuredArticlesWrapper {
    padding: rem(64) rem(16) 0;

    @include desktop-xl {
        padding: rem(64) 0 0;
    }
}

.noArticlesFoundWrapper {
    padding: rem(64);

    h2 {
        text-align: center;
        text-transform: capitalize;
    }
}
.outer {
    display: flex;
    flex-direction: column;
    gap: rem(15);
    
    @include desktop {
        gap: rem(20);
        @include contained;
    }


    & .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        & .title {
            font-size: rem(28);
            font-weight: 600;
            line-height: 150%;
        }

        & .link {
            display: flex;
            align-items: center;
            gap: rem(5);
            @include regularLink;

            & svg {
                height: rem(20);
                aspect-ratio: 1;
            }
        }
    }
    
    & .articles {
        display: flex;
        gap: rem(20);
        flex-wrap: wrap;

        @include tablet {
            flex-wrap: nowrap;
        }

        & > * {
            flex-grow: 1;
            width: 100%;

            @include desktop {
                width: 100%;
                max-width: 33%;
            }

            & article {
                max-width: unset !important;

                @include desktop {
                    max-width: rem(472);;
                }
            }
        }
    }
}