@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.section {
	@include contained;
	margin-top: rem(128);
}

.heading {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;

	@include mobile-sm {
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		margin-bottom: 32px;
	}

	h3 {
		margin: 0;
	}

	svg {
		fill: $secondary-steel-900;
		width: 24px;
		height: 24px;
	}

	a {
		@include regularLink;
		display: flex;
		align-items: flex-start;
	}
}

.SelectTrigger {
	border: 1px solid $primary-fog-900;
	padding: rem(8) rem(12);
	color: $primary-midnight-900;
	background-color: $shade-white;
	display: flex;
	align-items: center;
	width: 300px;
	justify-content: space-between;
	position: relative;

	svg {
		width: 24px;
		height: 24px;
		fill: $primary-midnight-900;
	}

	&:hover {
		background-color: $primary-fog-900;
	}

	&:focus-visible {
		outline: 2px solid $information-300;
	}
}

.SelectContent[data-state='open'] {
	background-color: $shade-white;
	border: 2px solid $primary-fog-900;
	overflow: hidden;
	position: absolute;
	padding: rem(10);
	top: 0;
	width: 300px;
	z-index: 10;
	left: 19px;
}

.SelectItem[data-highlighted] {
	color: $primary-midnight-900;
	padding: rem(10) rem(20);
	background-color: $primary-fog-700;
	border: 1px solid $primary-fog-900;
}

.SelectItem {
	color: $primary-midnight-900;
	padding: rem(10) rem(20);
}

.SelectItemText {
	font-size: rem(13);
}

.courses {
	display: grid;
	margin-top: rem(8);
	gap: rem(16);

	grid-template-columns: repeat(1, 1fr);

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
		margin-top: rem(16);
	}

	@include desktop {
		grid-template-columns: repeat(3, 1fr);
	}
}

.course {
	display: flex;
	flex-direction: column;
	gap: rem(8);
	padding: rem(8);

	&.limited {
		background-color: $secondary-steel-900;

		& svg {
			height: 24px;
			width: 24px;
		}

		p {
			display: inline-flex;
			align-items: center;
			color: $shade-white;
			gap: 8px;
			margin: 0;
			@include paragraph03;
			font-weight: 600;
		}
	}

	& .details {
		background-color: $shade-white;
		padding: rem(32);
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		gap: rem(12);

		h4 {
			line-height: 150%;
		}

		.dates {
			color: $primary-cardinal-900;
			font-size: 13px;
			margin: 0;
		}

		a {
			@include underlinedLink;
			display: inline-flex;
			align-items: center;
			gap: rem(8);
		}
	}
}
