@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.contentWrapper {
    display: flex;
    flex-direction: column;
    padding: rem(64) rem(24);
    background-color: $shade-white;
    margin-top: rem(32);
    max-width: rem(1456);

    @include tablet {
        flex-direction: row;
        gap: rem(32);
        padding: rem(64);
        margin: 0 auto;
        margin-top: rem(24);
    }
}

.imgContainer {
    margin-bottom: rem(28);
    width: rem(128);
    min-width: rem(128);

    @include tablet {
        width: rem(256);
        min-width: rem(256);
    }

    img {
        width: 100%;
    }
}

.content {
    h2 {
        margin-bottom: rem(12);
        font-weight: 600;
    }

    .markdown {
		line-height: 150%;
        margin-bottom: rem(24);

        @include tablet {
            font-size: rem(19);
        }
    }

    .button {
        width: 100%;

        @include tablet {
            width: unset;
        }
    }
}