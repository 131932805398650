@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.slideWrapper {
    background-color: var(--custom-background-color);
    position: relative;
    padding: 0 0 6rem;

    &.topPadding {
        padding: 6rem 0 ;
    }


    :global {
        .swiper{
            width: 100%;

            @include tablet {
                width: calc(100% - 144px);
            }

            .swiper-wrapper {
                padding-bottom: 1.5rem
            }

            .swiper-scrollbar {
                background-color: white;
                @include swiperScrollBar;
                position: relative;
                bottom: 0;
			}
        }
    }

    &>div {
        @include contained;

        width: 100%;
        display: block;
        position: relative;
    }

    img {
        width: 100%;
    }
}