@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.date {
	display: block;
	color: $primary-cardinal-900;
	font-size: rem(13);
	line-height: 150%;
	font-weight: 400;
}