@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.skeletonTemplate {
    max-width: rem(348);
	height: auto;
}

.card {
	display: flex;
	flex-direction: column;
	gap: rem(8);
	background-color: $primary-fog-50;
	height: 100%;
	width: 100%;

	& .inner {
		background-color: white;
		margin: 0;
		padding: 1rem;
		flex: 1;
		display: flex;
		flex-direction: column;

		& .pricingContainer,
		& .login {
			margin-top: auto;
		}

		& .login {
			@include underlinedLink;
		}

		.skeletonDetails p{
			background-color: $primary-fog-900;
			width: 100%;
			height: rem(20);
			border-radius: 20px;
		}

		p:last-child {
			width: 70%;
		}
	}
}

.cardSmall {
	display: flex;
	flex-direction: row;
	gap: rem(8);
	background-color: $primary-fog-50;
	height: 100%;
	width: 100%;

	& .inner {
		background-color: white;
		margin: 0;
		padding: 1rem;
		flex: 1;
		display: flex;
		flex-direction: row;
		gap: rem(8);
		align-items: flex-start;

		.skeletonDetails {
			width: 80%;
			height: rem(20);
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			p {
				background-color: $primary-fog-900;
				height: rem(24);
				border-radius: 3px;
				width: 80%;
				margin: 0 0 2px 0;
			}

			p:last-child {
				margin-top: rem(16);
			}
		}
	}
}
