@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.card {
	display: flex;
	flex-direction: column;
	background-color: $shade-white;
	height: 100%;

	@include mobile {
		max-width: rem(472);
	}

	.readMore {
		padding: rem(16);
	}
}

.link {
	color: inherit;

	.readMore {
		margin-top: auto;
		color: $secondary-steel-900;
		font-size: rem(16);
		font-weight: 600;
		line-height: 150%;
		text-decoration: underline;
	}

	&:hover {
		.readMore {
			color: $secondary-steel-500;
		}
	}

	&:active {
		.readMore {
			color: $primary-midnight-900;
		}
	}
}

.image {
	aspect-ratio: 472 / 295;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.content {
	display: flex;
	flex-direction: column;
	padding: rem(16);

	a {
		margin-top: auto;
	}

	p {
		font-size: rem(16);
		margin-top: rem(8);
		display: -webkit-box;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.title {
	font-size: rem(19);
	font-weight: 700;
	line-height: 150%;
	margin-bottom: 0;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
  