@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.twoPanelQuoteBlockSection {
    background-color: $secondary-steel-900;

    &.toggleBorder {
        @include tablet {
            &:before {
                content: ""; 
                display: block;
                margin: 0 auto;
                max-width: 1328px;
                width: 87%;
                border-top: 1px solid $secondary-steel-300;
            }
        }
    }

        
        .twoPanelQuoteBlockWrapper {
            @include contained;
            padding: rem(64) rem(24);
            
            @include tablet {
                display: flex;
                gap: rem(64);
                flex-direction: row;
                padding: rem(96) rem(64);
            }

        &.alignVideo {
            align-items: center;
        }
    }
    .markdown > * {
        color: $shade-white;
    }

    p,
    div {
        font-size: 23px;
        color: $shade-white;
    }

    h5 {
        font-weight: 400;
    }

    .leftPanel {
        flex: 1;
        margin: 0 0 rem(64);

        @include tablet {margin: 0;}



        & .imageContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            aspect-ratio: 16/10;
            overflow: hidden;
    
            & > div {
                height: 100% !important;
                width: 100% !important;
                position: relative;
    
                & video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
    
                & .playIcon {
                    height: rem(50);
                    position: absolute;
                    color: $primary-cardinal-900;
                }
    
                & > div {
                    position: absolute;
                }
    
                &:hover .playIcon {
                    color: $primary-cardinal-700;
                }
            }
    
            & img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .swiper {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        :global {
            .swiper-scrollbar {
                position: relative;
                background: $shade-white;
                height: rem(2);
                .swiper-scrollbar-drag {
                    background: $primary-cardinal-900;
                    height: 101%;
                }
    
                width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 0%));
                left: var(--swiper-scrollbar-sides-offset, 0%);
            }
        }
    }
    
    .quoteArea {
        margin: 0 auto;
        color: $shade-white;
        width: 100%;

        h2 {
            font-size: 28px;
            font-weight: 600;
            margin: 0 0 rem(32);
            color: $shade-white;
        }
    
        .quoteText {
            margin: 0 0 rem(32);
        }
    
        .quoteAuthor {
            margin: 0 0 10px;
    
            &:last-of-type {
                margin: 0 0 32px;
            }
        }
    
    }

    .navigationButtons {
        margin-top: rem(32);
        display: flex;
        justify-content: space-between;
        padding: rem(2);
    }
    
    .carouselArrowButtons {
        width: rem(48);
        height: rem(48);
        box-sizing: border-box;
        font-family: inherit;
        border: solid 1px transparent;
        border-radius: 50%;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        background-color: $shade-white;
        outline: none;
        display: none;
    
        @include tablet {
            display: block;
        }
    
        i {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
    
            svg {
                width: 40px;
                height: 40px;
                fill: $primary-cardinal-900;
                color: $primary-cardinal-900;
            }
        }
    
        &:focus,
        &:focus-within,
        &:focus-visible {
            background-color: $shade-white;
            outline: 2px solid $information-300;
        }
    
        &:hover {
            transition: 300ms ease-in-out;
            background-color: $primary-cardinal-900;
            outline: none;
            svg {
                fill: $shade-white;
                color: $shade-white;
            }
        }
    
        &:active,
        .active {
            background-color: $primary-midnight-900;
            transition: none;
            outline: none;
            svg {
                fill: $shade-white;
                color: $shade-white;
            }
        }
    
        &[disabled],
        &:disabled {
            cursor: not-allowed;
            background-color: $primary-cardinal-300;
            border-color: $primary-cardinal-300;
            color: $shade-white;
            outline: none;
        }
    }
}