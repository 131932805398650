@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.button {
	width: 100% !important;
}

.modal {
    max-width: rem(1200) !important;
    height: 85%;
    overflow: hidden;
	z-index: 120;

    @include tablet {
        max-height: 550px;
	}
}

.hideModal {
	display: none;
}

.inner {
    height: 93%;
    overflow-y: auto;
}

.grid {
	display: grid;
	grid-template-columns: 1fr;
	gap: rem(8);
    margin-top: rem(20);
    overflow-y: auto;

	@include mobile {
		grid-template-columns: repeat(2, 2fr);
	}

	@include tablet {
		grid-template-columns: repeat(3, 1fr);
	}
}
